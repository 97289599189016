<template>
  <v-form
    class="w-100"
    ref="clientForm"
    @submit.prevent="submitClientForm"
  >
    <v-row>
      <v-col
        cols="12"
        class="pt-0 mb-2 d-flex align-end"
        v-if="!editingClient"
      >
        <div class="ml-auto">
          <v-radio-group
            row
            v-model="client.clientType"
            hide-details
          >
            <v-radio
              v-for="type in filters.clientTypes"
              :key="type"
              :value="type"
              :label="type"
            />
          </v-radio-group>
        </div>
      </v-col>

      <v-col
        cols="12"
        class="d-flex align-start"
      >
        <v-text-field
          outlined
          label="Nr NIP"
          v-model="nipQuery"
          :rules="isCompanyClient ? [rules.required, rules.nipNumber] : []"
          :disabled="!isCompanyClient || !!editingClient"
          placeholder="Wpisz numer NIP"
          validate-on-blur
          v-mask="'##########'"
          @keyup.enter="fetchGusData"
        />
        <v-btn
          color="primary"
          class="ml-2"
          outlined
          icon
          :disabled="gusSearchDisabled || !isCompanyClient"
          :loading="processing"
          @click="fetchGusData"
        >
          <v-icon color="primary">
            mdi-magnify
          </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="client.name"
          outlined
          label="Nazwa klienta"
          :disabled="isCompanyClient && !isClientFound"
          :rules="[rules.required]"
          placeholder="Wpisz nazwę klienta"
        />
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          v-model="client.patronId"
          :items="users"
          :disabled="isCompanyClient && !isClientFound"
          placeholder="Wybierz opiekuna klienta"
          label="Opiekun"
          item-text="fullName"
          item-value="id"
          append-icon="mdi-chevron-down"
          outlined
          dense
        />
      </v-col>
      <v-col cols="12">
        <v-autocomplete
          v-model="client.middlemanId"
          :items="middlemen"
          :disabled="isCompanyClient && !isClientFound"
          placeholder="Wybierz pośrednika klienta"
          label="Pośrednik"
          item-text="name"
          item-value="id"
          clearable
          append-icon="mdi-chevron-down"
          outlined
          dense
        />
      </v-col>
      <v-col cols="6">
        <v-autocomplete
          outlined
          :items="bdoTypes"
          label="Logika BDO"
          placeholder="Wybierz logikę BDO"
          item-text="type"
          item-value="type"
          :disabled="isCompanyClient && !isClientFound"
          v-model="client.bdoType"
          :rules="[rules.required]"
          append-icon="mdi-chevron-down"
          dense
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="client.bdoNumber"
          outlined
          label="Nr BDO"
          :disabled="isCompanyClient && !isClientFound"
          :rules="exemptFromBdo ? [] : [rules.required]"
          placeholder="Wpisz numer BDO"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="client.controlName"
          outlined
          label="Dane przekazującego"
          :rules="isControlNameRequired ? [rules.required] : []"
          placeholder="Wpisz dane przekazującego"
        />
      </v-col>
      <v-col
        cols="6"
      >
        <v-text-field
          v-model.number="client.purchaseLimit"
          outlined
          label="Limit kupiecki [zł]"
          type="number"
          :disabled="isCompanyClient && !isClientFound"
          :rules="[rules.money, rules.lowerThan(10000001)]"
          validate-on-blur
          placeholder="Wpisz wysokość limitu"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="client.phoneNumber"
          outlined
          label="Numer telefonu"
          :disabled="isCompanyClient && !isClientFound"
          :rules="[rules.phoneNumber]"
          v-mask="getFieldMask('phoneNumber')"
          placeholder="Wpisz numer telefonu"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="client.email"
          outlined
          label="Email"
          :disabled="isCompanyClient && !isClientFound"
          :rules="[rules.email]"
          validate-on-blur
          placeholder="Wpisz adres email"
        />
      </v-col>
      <v-col
        cols="12"
        class="mt-4"
      >
        <v-textarea
          v-model="client.notes"
          outlined
          :rows="2"
          auto-grow
          :disabled="isCompanyClient && !isClientFound"
          label="Notatka do klienta"
          placeholder="Wpisz notatkę do klienta"
        />
      </v-col>
      <v-col
        cols="12"
        class="pt-0"
      >
        <div class="d-flex justify-space-between">
          <div class="form__section-title d-flex align-center">
            Dane do faktury
            <v-btn
              icon
              x-small
              color="primary"
              class="ml-2 mt-1"
              title="Skopiuj dane do faktury"
              @click="copyInvoiceData"
            >
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-col
        cols="12"
        v-if="isCompanyClient"
      >
        <v-text-field
          v-model="client.invoiceBuyerName"
          outlined
          label="Nabywca"
          :disabled="!isClientFound"
          :rules="[rules.required]"
          placeholder="Wpisz nazwę nabywcy"
        />
      </v-col>

      <v-col
        v-else
        cols="12"
      >
        <v-autocomplete
          outlined
          :items="settlementTypes"
          label="Typ dokumentu"
          placeholder="Wybierz typ dokumentu"
          v-model="client.settlementType"
          :disabled="settlementTypes && settlementTypes.length === 1"
          :rules="[rules.required]"
          append-icon="mdi-chevron-down"
          dense
        />
      </v-col>

      <v-col cols="8">
        <v-text-field
          v-model="client.invoiceAddress"
          outlined
          label="Adres"
          validate-on-blur
          :rules="[isCompanyClient ? rules.required : true]"
          :disabled="isCompanyClient && !isClientFound"
          placeholder="Wpisz ulicę i nr lokalu"
        />
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-model="client.invoicePhoneNumber"
          outlined
          label="Numer telefonu"
          :rules="[rules.phoneNumber]"
          placeholder="Wpisz numer telefonu"
          :disabled="isCompanyClient && !isClientFound"
          v-mask="getFieldMask('phoneNumber')"
          validate-on-blur
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
        class="pb-0"
      >
        <v-text-field
          v-model="client.invoicePostalCode"
          outlined
          label="Kod pocztowy"
          :rules="[isCompanyClient ? rules.required : true, rules.postCode]"
          :disabled="isCompanyClient && !isClientFound"
          validate-on-blur
          placeholder="xx-xxx"
          v-mask="getFieldMask('postCode')"
        />
      </v-col>
      <v-col
        cols="12"
        md="8"
        class="pb-0"
      >
        <v-text-field
          v-model="client.invoiceCity"
          outlined
          label="Miejscowość"
          :disabled="isCompanyClient && !isClientFound"
          :rules="isCompanyClient ? [rules.required] : []"
          placeholder="Wpisz nazwę miejscowości"
        />
      </v-col>
      <v-col
        cols="12"
        class="pb-0"
      >
        <v-checkbox
          v-model="client.signatureRequired"
          hide-details
          class="mt-0"
          :disabled="isCompanyClient && !isClientFound"
          label="Podpis wymagany"
        />
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <v-checkbox
          v-model="client.isMiddleman"
          hide-details
          class="mt-0"
          :disabled="isCompanyClient && !isClientFound"
          label="Czy pośrednik?"
        />
      </v-col>
      <v-col
        v-if="actions"
        class="py-0"
      >
        <v-btn
          text
          small
          @click="$emit('close')"
        >
          Anuluj
        </v-btn>

        <v-btn
          color="primary"
          type="submit"
          text
          small
        >
          Zapisz klienta
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import rules from '../../../utils/validators'
import {
  parseAsBasicUnit,
  getFieldMask,
  formatChangedPhoneNumber,
} from '../../../utils'
import { mapState, mapActions } from 'vuex'
import { Client } from '../../../models'
import debounce from 'lodash/debounce'

export default {
  props: {
    actions: {
      type: Boolean,
      default: false,
    },
    editingClient: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      rules,
      client: new Client(),
      isClientFound: false,
      nipQuery: '',
    }
  },
  computed: {
    ...mapState({
      filters: state => state.core.filters,
      clientFilters: state => state.tables.clients.filters,
      users: state => state.users.items,
      middlemen: state => state.middlemen.items,
      processing: state => state.client.processing,
    }),
    settlementTypes() {
      return this.filters.settlementTypes.filter(type => {
        return this.isCompanyClient ? type === 'Faktura' : type !== 'Faktura'
      })
    },
    bdoTypes() {
      return this.isCompanyClient
        ? this.filters.clientBdoTypes.filter(type => type.company)
        : this.filters.clientBdoTypes.filter(type => !type.company)
    },
    gusSearchDisabled() {
      const invoiceNip = this.nipQuery
      return invoiceNip ? invoiceNip.toString().length !== 10 : !invoiceNip
    },
    isCompanyClient() {
      return this.client.clientType === 'Firma'
    },
    isControlNameRequired() {
      return ['Oświadczenie', 'Zwolniony'].includes(this.client.bdoType)
    },
    exemptFromBdo() {
      return this.filters.clientBdoTypes
        ?.filter(type => type.exempt)
        ?.map(type => type.type)
        ?.includes(this.client.bdoType)
    },
  },
  created() {
    if (this.editingClient) {
      this.isClientFound = true
      const baseClient = { ...this.editingClient }
      baseClient.purchaseLimit = baseClient.purchaseLimit / 100
      this.client = new Client(baseClient)
      this.nipQuery = this.editingClient.invoiceNip
    }
    const clientType = this.clientFilters.find(
      filter => filter.name === 'clientType'
    )?.filterBy
    if (this.$route.name === 'clients' && clientType) {
      this.client.clientType = clientType
    }
  },
  watch: {
    'client.clientType'() {
      this.$refs.clientForm.resetValidation()
      this.client.settlementType = this.settlementTypes[0]
      const bdoTypes = this.bdoTypes.map(type => type.type)
      if (!bdoTypes.includes(this.client.bdoType)) this.client.bdoType = null
    },
    'client.phoneNumber'(value) {
      if (value && !value.includes('-') && value.length === 9) {
        this.$nextTick(() => {
          this.client.phoneNumber = String(this.formatChangedPhoneNumber(value))
        }) // v-mask doesn't work when phone number has length of 9 digits. (format #########), problem is visible especially when you overwrite/overpaste phone number with new value.
      }
    },
    bdoTypes: {
      handler(value) {
        if (value.length === 1) {
          this.client.bdoType = value[0].type
        }
      },
      immediate: true
    } // if there is only one bdo type available, set it as default - it's case when we have indiviudal client and bdo logic "indiviudal"
  },
  methods: {
    ...mapActions({
      showSnackbar: 'snackbar/showSnackbar',
      fetchGusData: debounce(
        function(dispatch) {
          dispatch('client/fetchGusData', this.nipQuery).then(data => {
            this.client.invoiceNip = this.nipQuery
            this.isClientFound = true
            const {
              invoiceAddress,
              invoiceCity,
              invoicePostalCode,
              phoneNumber,
              name,
              bdoNumber,
              message,
            } = data
            this.client.invoiceAddress = invoiceAddress
            this.client.invoiceBuyerName = name
            this.client.name = name
            this.client.invoiceCity = invoiceCity
            this.client.invoicePostalCode = invoicePostalCode
            this.client.invoicePhoneNumber = phoneNumber
            if (message) {
              this.showSnackbar({ message: [message], type: 'error' })
            } else {
              this.client.bdoNumber = bdoNumber
            }
          }).catch(() => {
            this.nipQuery = this.client.invoiceNip
          })
        },
        3000,
        { leading: true }
      ),
    }),
    getFieldMask,
    formatChangedPhoneNumber,
    copyInvoiceData() {
      this.client.invoiceBuyerName = this.client.name
      this.client.invoicePhoneNumber = this.client.phoneNumber
    },
    submitClientForm() {
      const client = {
        ...this.client,
        purchaseLimit: parseAsBasicUnit(this.client.purchaseLimit),
      }
      if (this.$refs.clientForm.validate()) {
        this.$emit('submitClientForm', client)
      }
    },
  },
}
</script>
